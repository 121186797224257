import { FC } from "react";
import { Button } from "components/Button";
import {
  SelectInput,
  StandardOption
} from "components/formik/SelectField"
import { TextInput } from "components/formik/TextField";
import { ZERO_WIDTH_SPACE } from "constants/constants"
import { gql, useQuery } from "@apollo/client";
import { FilterForm } from "components/FilterForm";
import { FilterField } from "components/FilterField";

export interface FilterModel {
  dueStatus?: "ALL" | "PAST_DUE" | "UPCOMING";
  caseNumber?: string;
}

export const defaultValue: FilterModel = {
  dueStatus: "ALL",
  caseNumber: "",
};

interface FilterPanelProps {
  value?: FilterModel;
  onChange(filter: FilterModel): void;
  isLoading?: boolean;
}

export const FilterPanel: FC<FilterPanelProps> = (props) => {
  const { value = defaultValue, onChange, isLoading = false } = props;

  return (
    <FilterForm<FilterModel>
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
    >
      <div className="flex items-end gap-2">
        <FilterField htmlFor="dueStatus" icon="filter" label="Timing Status" className="flex-grow">
          <SelectInput
            name="dueStatus"
            options={[
              { value: "ALL", label: "All" },
              { value: "PAST_DUE", label: "Past Due" },
              { value: "UPCOMING", label: "Upcoming" },
            ]}
          />
        </FilterField>

        <FilterField htmlFor="caseNumber" icon="search" label="Case Number / Episode ID" className="flex-grow">
          <TextInput
            name="caseNumber"
            icon="search"
            placeholder="Case Number / Episode ID"
          />
        </FilterField>

        <FilterField label={ZERO_WIDTH_SPACE}>
          <Button
            type="submit"
            color="gold"
            disabled={isLoading}
            isLoading={isLoading}
          >
            Apply
          </Button>
        </FilterField>
      </div>
    </FilterForm>
  )
}

