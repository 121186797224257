import { FC, useCallback } from "react";
import { QueryHookOptions, QueryResult, gql, useMutation, useQuery } from '@apollo/client';
import { Drawer, DrawerHeader } from "components/Drawer";
import { Spinner } from "components/Spinner";
import { JsonDebugger } from "components/JsonDebugger";
import { AvailableProvidersSearch } from "../AssignMDDrawer/AvailableProvidersSearch";
import { toast } from "react-hot-toast";

const PROVIDER_AVAILABILITY_FILTER = gql`
  query CaseProviderAvailabilityFilter($id: UUID4!) {
    caseProfile(id: $id) {
      id
      modalityId
      isUrgent
      umReviewDueAt
      umAssignmentThrottled
      inSslSignOff
      caseAssignmentConstraints {
        similarSpecialtyRequired
        sameStateLicensureRequired
        exclusivelyPediatric
      }
      umRequest {
        id
        assignedPhysician {
          id
        }
      }
    }
    caseProviderAvailabilityFilter(id: $id) {
      includePediatric
      exclusivelyPediatric
      skillSearchModes
      providerDomainIds
      excludeProviderDomainIds
      icd10
      shiftBuffer
      shiftStartBuffer
      shiftEndBuffer
      skills {
        id
        name
        caseSystem
      }
      licensedInStates {
        id
        name
        abbreviation
      }
      externalSystemName
    }
  }
`;

interface Data {
  caseProfile: CaseProfileForAssignUMReviewer;
  caseProviderAvailabilityFilter: CaseProviderAvailabilityFilter;
}

export interface CaseProfileForAssignUMReviewer {
  id: string;
  modalityId: string;
  isUrgent: boolean;
  umReviewDueAt: null | string;
  umAssignmentThrottled: boolean;
  inSslSignOff: boolean;
  caseAssignmentConstraints: {
    similarSpecialtyRequired: boolean;
    sameStateLicensureRequired: boolean;
    exclusivelyPediatric: boolean;
  };
  umRequest: {
    id: string;
    assignedPhysician?: {
      id: string;
    }
  };
}

export type SkillSearchMode = "skills" | "icd10";

// The one we retrieve
export interface CaseProviderAvailabilityFilter {
  includePediatric: boolean;
  exclusivelyPediatric: boolean;
  skillSearchModes: SkillSearchMode[];
  providerDomainIds: null | string[];
  excludeProviderDomainIds: null | string[];
  icd10: null | string;
  shiftBuffer: null | number;
  shiftStartBuffer: null | number;
  shiftEndBuffer: null | number;
  skills: {
    id: string;
    name: string;
    caseSystem: string;
  }[];
  licensedInStates: {
    id: string;
    name: string;
    abbreviation: string;
  }[];
  externalSystemName: string;
}

const ASSIGN_PROVIDER = gql`
  mutation AssignProvider($umRequestId: UUID4!, $providerId: UUID4!) {
    assignUmRequestReviewer(umRequestId: $umRequestId, providerId: $providerId) {
      errors {
        key
        message
      }
      umRequest {
        id
        assignedPhysician {
          id
        }
      }
    }
  }
`;

interface MutationData {
  assignUmRequestReviewer: {
    errors?: InputError[];
    umRequest?: {
      id: string;
      assignedPhysician: {
        id: string;
      }
    }
  }
}

export function useProviderAvailabilityFilter(options: QueryHookOptions<Data>): QueryResult<Data> {
  return useQuery<Data>(PROVIDER_AVAILABILITY_FILTER, options);
}

interface AssignUMReviewerDrawerProps {
  caseProfileId?: string;
  isOpen: boolean;
  onClose(): void;
  onSuccess(): void;
};

export const AssignUMReviewerDrawer: FC<AssignUMReviewerDrawerProps> = props => {
  const { isOpen, onClose, onSuccess, caseProfileId } = props;

  const { data, loading, error } = useProviderAvailabilityFilter({ variables: { id: caseProfileId }, skip: !caseProfileId });

  const [assignProviderMut] = useMutation<MutationData>(ASSIGN_PROVIDER)

  const umRequest = data?.caseProfile.umRequest;
  const assignProvider = useCallback((providerId: string) => {
    if (umRequest) {
      return assignProviderMut({ variables: { umRequestId: umRequest.id, providerId: providerId } }).then(resp => {
        if (resp.data?.assignUmRequestReviewer.errors) {
          toast.error(resp.data.assignUmRequestReviewer.errors[0].message);
        } else if (resp.data?.assignUmRequestReviewer.umRequest) {
          // it worked...
          toast.success("Physician assigned.")
          return onSuccess();
        }
      })
    }
    return Promise.resolve();
  }, [assignProviderMut, umRequest, onSuccess])

  return (
    <Drawer width="5xl" className="AssignUMReviewerDrawer" isOpen={isOpen} onRequestClose={onClose}>
      <DrawerHeader icon="user-md" title="Assign UM Reviewer" onClose={onClose} />
      <div className="p-4">
        {loading ? (
          <div className="p-6 text-center">
            <Spinner />
          </div>
        ) : error || !data?.caseProviderAvailabilityFilter ? (
          <p>Failed to load.</p>
        ) : !umRequest ? (
          <div className="px-8 py-4 font-semibold bg-gray-100 border rounded-md">
            <p>No active UM review request for case.</p>
          </div>
        ) : (
          <>
            <div className="pb-4">
              <AssignmentConstraints
                inSslSignOff={data.caseProfile.inSslSignOff}
                constraints={data.caseProfile.caseAssignmentConstraints}
              />
            </div>
            <AvailableProvidersSearch
              modalityId={data.caseProfile.modalityId}
              assignedProviderId={data.caseProfile.umRequest?.assignedPhysician?.id}
              caseFilterData={{ ...data.caseProviderAvailabilityFilter, includeSmes: false }}
              onProviderClick={assignProvider}
            />
            <JsonDebugger data={data} />
          </>
        )}
      </div>
    </Drawer>
  );
};

function AssignmentConstraints(props: {
  inSslSignOff: boolean;
  constraints: CaseProfileForAssignUMReviewer["caseAssignmentConstraints"]
}) {
  const { inSslSignOff, constraints } = props;

  return (
    <div className="px-8 py-4 font-semibold bg-gray-100 border rounded-md">
      <p>
        <strong>Assignment Rules: {inSslSignOff ? "(2nd Touch)" : null}</strong>
      </p>
      <ul className="mt-3">
        <li>Similar Specialty Required: <span className={constraints.similarSpecialtyRequired ? "text-green-600" : "text-gray-500"}>
          {constraints.similarSpecialtyRequired ? "Yes" : inSslSignOff ? "Completed" : "No"}
        </span></li>
        <li>Same State Licensure Required: <span className={constraints.sameStateLicensureRequired ? "text-green-600" : "text-gray-500"}>
          {constraints.sameStateLicensureRequired ? "Yes" : "No"}
        </span></li>
        <li>Exclusively Pediatric: <span className={constraints.exclusivelyPediatric ? "text-green-600" : "text-gray-500"}>
          {constraints.exclusivelyPediatric ? "Yes" : "No"}
        </span></li>
      </ul>
    </div>
  )
}
