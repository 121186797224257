import { FC } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import {
  Layout,
  Sidebar,
  SidebarLink,
  PageContent,
} from "layouts/PortalLayout";
import { NoMatchScreen } from "screens/NoMatchScreen";
import { AppealQueuesScreen } from "screens/InternalPortal/AppealQueuesScreen/AppealQueuesScreen";

interface ExternalPortalProps { }

export const ExternalPortal: FC<ExternalPortalProps> = (props) => {
  const match = useRouteMatch();
  return (
    <Layout>
      <Sidebar userDisplayName="External Provider">
        <SidebarLink to="/ep/dashboard" icon="home">
          Dashboard
        </SidebarLink>
        <SidebarLink to="/ep/team" icon="users">
          Team
        </SidebarLink>
        <SidebarLink to="/ep/projects" icon="folder">
          Projects
        </SidebarLink>
        <SidebarLink to="/ep/calendar" icon="calendar">
          Calendar
        </SidebarLink>
        <SidebarLink to="/ep/appeals" icon="inbox">
          Post-Decision Requests
        </SidebarLink>
        <SidebarLink to="/ep/analytics" icon="chartBar">
          Analytics
        </SidebarLink>
      </Sidebar>
      <PageContent>
        <Switch>
          <Route path={`${match.path}appeals`}>
            <AppealQueuesScreen />
          </Route>
          <Route path="*">
            <NoMatchScreen />
          </Route>
        </Switch>
      </PageContent>
    </Layout>
  );
};
