import { FC } from "react";
import UserPlusIcon from '@heroicons/react/24/solid/UserPlusIcon';
import ExclamationTriangleIcon from '@heroicons/react/24/solid/ExclamationTriangleIcon';

import { distanceInWords, hMmA, mmDdYyyy } from "utils/dateFormatters";
import { Tooltip } from "components/Tooltip";

type UmRequestEventName = keyof UmRequestEventDataModels;

export interface UmRequestEventModel<T extends UmRequestEventName = UmRequestEventName> {
  id: string;
  type: T;
  insertedAt: string;
  rollupId: string;
  agent: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
  data: UmRequestEventDataModels[T];
}

type UmRequestEventDataModels = {
  md_assigned: MdAssignedEventDataModel;
  md_assignment_failed: MdAssignmentFailedEventDataModel;
  warning_cleared: WarningClearedEventDataModel;
}

interface BaseUmRequestEventDataModel {
  eventType: UmRequestEventName;
}

interface MdAssignedEventDataModel extends BaseUmRequestEventDataModel {
  __typename: "MdAssignedEventData";
  eventName: "md_assigned";
  providerId: string;
  providerName: string;
}

interface MdAssignmentFailedEventDataModel extends BaseUmRequestEventDataModel {
  __typename: "MdAssignmentFailedEventData";
  eventName: "md_assignment_failed";
  providerId: string | null;
  providerName: string | null;
  searchFilter: any;
  reason: string | null;
}

interface WarningClearedEventDataModel extends BaseUmRequestEventDataModel {
  __typename: "WarningClearedEventData";
  eventName: "warning_cleared";
  via: string;
}

const components: Record<UmRequestEventName, FC<{ event: any }>> = {
  md_assigned: MdAssignedEvent,
  md_assignment_failed: MdAssignmentFailedEvent,
  warning_cleared: WarningClearedEvent,
}

const HoverTime: FC<{ timestamp: string, className?: string }> = props => {
  const { timestamp, className } = props;
  const cn = ["cursor-pointer", className].filter(Boolean).join(" ");
  return (
    <span className={cn}>
      <Tooltip tip={<p>{mmDdYyyy(timestamp)} at {hMmA(timestamp)}</p>}>
        {distanceInWords(timestamp)} ago
      </Tooltip>
    </span>
  );
}

/**
 * UmRequestEvent.
 */
interface UmRequestEventProps {
  showLine?: boolean;
  umRequestEvent: UmRequestEventModel;
}

export const UmRequestEvent: FC<UmRequestEventProps> = props => {
  const { showLine = false, umRequestEvent } = props;
  const Component = components[umRequestEvent.type];

  return (
    <li>
      <div className="relative pb-8">
        {showLine ? (
          <span
            className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          ></span>
        ) : null}
        <Component event={umRequestEvent} />
      </div>
    </li>
  )
};

function MdAssignedEvent(props: {
  event: UmRequestEventModel<"md_assigned">;
}) {
  const { event } = props;
  const firstName = event.agent.firstName;
  const lastName = event.agent.lastName;
  return (
    <div className="relative flex items-start space-x-3">
      <div>
        <div className="relative px-1">
          <div className="bg-cool-gray-100 flex items-center justify-center w-8 h-8 rounded-full">
            <UserPlusIcon className="text-cool-gray-500 w-5 h-5" />
          </div>
        </div>
      </div>
      <div className="flex-1 min-w-0 py-0">
        <div className="text-cool-gray-500 text-sm">
          <span className="mr-0.5">
            <span className="text-cool-gray-900 font-medium">
              {firstName} {lastName}
            </span>{" "}
            assigned an MD reviewer: {event.data.providerName}
          </span>
          <span className="whitespace-nowrap ml-2 text-xs">
            <HoverTime timestamp={event.insertedAt} />
          </span>
        </div>
      </div>
    </div>
  );
}

function MdAssignmentFailedEvent(props: {
  event: UmRequestEventModel<"md_assignment_failed">;
}) {
  const { event } = props;
  const firstName = event.agent.firstName;
  const lastName = event.agent.lastName;
  return (
    <div className="relative flex items-start space-x-3">
      <div>
        <div className="relative px-1">
          <div className="bg-cool-gray-100 flex items-center justify-center w-8 h-8 rounded-full">
            <ExclamationTriangleIcon className="text-cool-gray-500 w-5 h-5" />
          </div>
        </div>
      </div>
      <div className="flex-1 min-w-0 py-0">
        <div className="text-cool-gray-500 text-sm">
          <span className="mr-0.5">
            <span className="text-cool-gray-900 font-medium">
              {firstName} {lastName}
            </span>{" "}
            attempted to assign an MD reviewer {event.data.providerName ? `(${event.data.providerName})` : ""}, but assignment failed. {event.data.reason ? `Reason: ${event.data.reason}` : null}
          </span>
          <span className="whitespace-nowrap ml-2 text-xs">
            <HoverTime timestamp={event.insertedAt} />
          </span>
        </div>
      </div>
    </div>
  );
}

function WarningClearedEvent(props: {
  event: UmRequestEventModel<"warning_cleared">;
}) {
  const { event } = props;
  const firstName = event.agent.firstName;
  const lastName = event.agent.lastName;
  return (
    <div className="relative flex items-start space-x-3">
      <div>
        <div className="relative px-1">
          <div className="bg-cool-gray-100 flex items-center justify-center w-8 h-8 rounded-full">
            <ExclamationTriangleIcon className="text-cool-gray-500 w-5 h-5" />
          </div>
        </div>
      </div>
      <div className="flex-1 min-w-0 py-0">
        <div className="text-cool-gray-500 text-sm">
          <span className="mr-0.5">
            <span className="text-cool-gray-900 font-medium">
              {firstName} {lastName}
            </span>{" "}
            cleared the MD assignment warning on this case via {event.data.via}.
          </span>
          <span className="whitespace-nowrap ml-2 text-xs">
            <HoverTime timestamp={event.insertedAt} />
          </span>
        </div>
      </div>
    </div>
  );
}
