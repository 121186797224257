import { FC, useCallback } from "react";
import { QueryHookOptions, QueryResult, gql, useMutation, useQuery } from '@apollo/client';
import { Drawer, DrawerHeader } from "components/Drawer";
import { Spinner } from "components/Spinner";
import { JsonDebugger } from "components/JsonDebugger";
import { AvailableProvidersSearch } from "./AvailableProvidersSearch";
import { toast } from "react-hot-toast";

const PROVIDER_AVAILABILITY_FILTER = gql`
  query CaseProviderAvailabilityFilter($id: UUID4!) {
    caseProfile(id: $id) {
      id
      modalityId
      currentAppealRequest {
        id
        assignedPhysician {
          id
        }
      }
    }
    caseProviderAvailabilityFilter(id: $id) {
      includePediatric
      exclusivelyPediatric
      skillSearchModes
      providerDomainIds
      excludeProviderDomainIds
      icd10
      shiftBuffer
      shiftStartBuffer
      shiftEndBuffer
      skills {
        id
        name
        caseSystem
      }
      licensedInStates {
        id
        name
        abbreviation
      }
      externalSystemName
    }
  }
`;

interface Data {
  caseProfile: {
    id: string;
    modalityId: string;
    currentAppealRequest?: {
      id: string;
      assignedPhysician?: {
        id: string;
      }
    };
  };
  caseProviderAvailabilityFilter: CaseProviderAvailabilityFilter;
}

export type SkillSearchMode = "skills" | "icd10";

// The one we retrieve
export interface CaseProviderAvailabilityFilter {
  includePediatric: boolean;
  exclusivelyPediatric: boolean;
  includeSmes?: null | boolean;
  skillSearchModes: SkillSearchMode[];
  providerDomainIds: null | string[];
  excludeProviderDomainIds: null | string[];
  icd10: null | string;
  shiftBuffer: null | number;
  shiftStartBuffer: null | number;
  shiftEndBuffer: null | number;
  skills: {
    id: string;
    name: string;
    caseSystem: string;
  }[];
  licensedInStates: {
    id: string;
    name: string;
    abbreviation: string;
  }[];
  externalSystemName: string;
}

const ASSIGN_PROVIDER = gql`
  mutation AssignProvider($appealRequestId: UUID4!, $providerId: UUID4!) {
    assignAppealRequestReviewer(appealRequestId: $appealRequestId, providerId: $providerId) {
      errors {
        key
        message
      }
      appealRequest {
        id
        assignedPhysician {
          id
        }
      }
    }
  }
`;

interface MutationData {
  assignAppealRequestReviewer: {
    errors?: InputError[];
    appealRequest?: {
      id: string;
      assignedPhysician: {
        id: string;
      }
    }
  }
}

export function useProviderAvailabilityFilter(options: QueryHookOptions<Data>): QueryResult<Data> {
  return useQuery<Data>(PROVIDER_AVAILABILITY_FILTER, options);
}

interface AssignMDDrawerProps {
  caseProfileId?: string;
  isOpen: boolean;
  onClose(): void;
  onSuccess(): void;
};

export const AssignMDDrawer: FC<AssignMDDrawerProps> = props => {
  const { isOpen, onClose, onSuccess, caseProfileId } = props;

  const { data, loading, error } = useProviderAvailabilityFilter({ variables: { id: caseProfileId }, skip: !caseProfileId });

  const [assignProviderMut] = useMutation<MutationData>(ASSIGN_PROVIDER)

  const currentAppealRequest = data?.caseProfile.currentAppealRequest;
  const assignProvider = useCallback((providerId: string) => {
    if (currentAppealRequest) {
      return assignProviderMut({ variables: { appealRequestId: currentAppealRequest.id, providerId: providerId } }).then(resp => {
        if (resp.data?.assignAppealRequestReviewer.errors) {
          toast.error(resp.data.assignAppealRequestReviewer.errors[0].message);
        } else if (resp.data?.assignAppealRequestReviewer.appealRequest) {
          // it worked...
          toast.success("Physician assigned.")
          return onSuccess();
        }
      })
    }
    return Promise.resolve();
  }, [assignProviderMut, currentAppealRequest, onSuccess])

  return (
    <Drawer width="5xl" className="AssignMDDrawer" isOpen={isOpen} onRequestClose={onClose}>
      <DrawerHeader icon="user-md" title="Assign MD" onClose={onClose} />
      <div className="p-4">
        {loading ? (
          <div className="p-6 text-center">
            <Spinner />
          </div>
        ) : error || !data?.caseProviderAvailabilityFilter ? (
          <p>Failed to load.</p>
        ) : !currentAppealRequest ? (
          <div className="px-8 py-4 font-semibold bg-gray-100 border rounded-md">
            <p>No active post-decision request for case.</p>
          </div>
        ) : (
          <>
            <AvailableProvidersSearch
              modalityId={data.caseProfile.modalityId}
              assignedProviderId={data.caseProfile.currentAppealRequest?.assignedPhysician?.id}
              caseFilterData={{ ...data.caseProviderAvailabilityFilter, includeSmes: true }}
              onProviderClick={assignProvider}
            />
            <JsonDebugger data={data} />
          </>
        )}
      </div>
    </Drawer>
  );
};
