import { FC, useMemo } from "react";
import {
  ApolloProvider as ReactApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  concat,
  HttpOptions,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { tokenStore, useAuth } from "./AuthContext";
import possibleTypes from "../possibleTypes.json";
import {
  createUploadMiddleware,
  uploadFetch,
} from "lib/absinthe-upload-client";
import toast from "react-hot-toast";

function customFetch(uri: any, options: any) {
  if (options.useUpload) {
    return uploadFetch(uri, options);
  }
  return fetch(uri, options);
}

const httpOpts: HttpOptions = {
  uri: `/graphql/api`,
  credentials: "same-origin",
  fetch: customFetch,
};

const uploadMiddleware = createUploadMiddleware(httpOpts);
const httpLink = concat(uploadMiddleware, createHttpLink(httpOpts));

const authLink = setContext((_, { headers }) => {
  const token = tokenStore.getStoredToken();
  return token && token.jwt
    ? { headers: { ...headers, authorization: `Bearer ${token.jwt}` } }
    : { headers };
});

const jsonScalarTypePolicy = {
  merge(_: any, value: string | null) {
    return value ? JSON.parse(value) : value;
  },
};

const cache = new InMemoryCache({
  possibleTypes,
  typePolicies: {
    AppealRequest: {
      fields: {
        postDecisionOptions: jsonScalarTypePolicy
      }
    },
    CaseEvent: {
      fields: {
        payload: jsonScalarTypePolicy
      }
    }
  }
});

function clientFactory(
  logout: () => void
): ApolloClient<NormalizedCacheObject> {
  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        for (let err of graphQLErrors) {

          switch (err.message) {
            case "unauthorized":
              toast.error("Unauthorized");
              break;

            case "unauthenticated":
              // Modify the operation context with a new token,
              // if available
              const oldHeaders = operation.getContext().headers;
              const oldJwt = extractJwt(oldHeaders?.authorization);
              const newToken = tokenStore.getStoredToken();

              // Only retry the operation if we have a new JWT
              // to throw at the server.
              if (newToken?.jwt && newToken.jwt !== oldJwt) {
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: `Bearer ${newToken.jwt}`,
                  },
                });
                // Retry the request, returning the new observable
                return forward(operation);
              } else {
                return logout();
              }
          }

          console.error(
            `[GraphQL error]: OperationName: ${operation.operationName}, Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`
          );
        }
      }

      if (networkError) {
        console.error(`[Network error]: ${networkError}`);
      }
      return;
    }
  );

  const link = authLink.concat(errorLink).concat(httpLink);

  return new ApolloClient({
    link,
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',  // Or 'no-cache' for development
      },
      query: {
        fetchPolicy: 'network-only',  // Or 'no-cache' for development
      }
    }
  });
}

export const ApolloProvider: FC = (props) => {
  const { children } = props;
  const { logout } = useAuth();

  const client = useMemo(() => clientFactory(logout), [logout]);

  return <ReactApolloProvider client={client}>{children}</ReactApolloProvider>;
};

function extractJwt(authorizationHeader: string | void): string | void {
  if (!authorizationHeader) return;
  return authorizationHeader.match(/Bearer\s(.+)/)?.[1];
}
