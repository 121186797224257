import { FC, useState } from "react";
import { gql, useQuery } from '@apollo/client';
import { ScreenTitle } from "context/ScreenTitle";
import { Table, TableContainer, TD, TH } from "components/Table";
import { NoResults } from "components/NoResults";
import { Spinner } from "components/Spinner";
import { FilterPanel, FilterModel, defaultFilter } from "./FilterPanel"
import { DropdownButton, DropdownItemButton } from "components/DropdownButton";
import { UpdateRoleModal } from "./UpdateRoleModal";

const LIST_AGENTS = gql`
  query ListAgents($first: Int, $after: UUID4, $filter: ListAgentsFilter) {
      agents(first: $first, after: $after, filter: $filter) {
        cursor
        endOfList
        items {
          id
          email
          name
          firstName
          lastName
          role
          timeZoneName
          lastSignInAt
        }
      }
    }
`;

interface Data {
  agents: Paginated<AgentModel>;
}

interface AgentModel {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  name: string;
  role: string;
  timeZoneName: string;
  lastSignInAt: string;
}

/**
 * UsersScreen.
 */

interface UsersScreenProps { };

type ActiveModal = { type: "UPDATE_ROLE", agentId: string };

export const UsersScreen: FC<UsersScreenProps> = props => {
  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);
  const [filter, setFilter] = useState<FilterModel>(defaultFilter);

  const { data, refetch, loading, error } = useQuery<Data>(LIST_AGENTS, { variables: { first: 300, filter } });

  return (
    <>
      <ScreenTitle title="Users" />
      <UpdateRoleModal
        isOpen={activeModal?.type === "UPDATE_ROLE"}
        onClose={() => setActiveModal(null)}
        onSuccess={() => refetch()}
        agentId={activeModal?.agentId}
      />
      <div className="_UsersScreen sm:rounded-md sm:overflow-hidden shadow">
        <div className="sm:p-6 px-4 py-6 bg-white">
          <div>
            <h2 id="users_heading" className="text-lg font-medium leading-6 text-gray-900">
              Users
            </h2>
            <p className="mt-1 text-sm text-gray-500">
              Manage system users
            </p>

            <div className="pt-6">
              <div className="flex items-start justify-between pb-4">
                <div>
                  <FilterPanel
                    value={filter}
                    onChange={setFilter}
                  />
                </div>
              </div>

              {
                loading ? (
                  <div className="p-8 text-center">
                    <Spinner />
                  </div>
                ) : error || !data?.agents ? (
                  <p>Failed to load.</p>
                ) : data.agents.items.length === 0 ? (
                  <NoResults icon="users" text="No matching users" />
                ) : (
                  <TableContainer>
                    <Table className="text-left">
                      <thead>
                        <tr>
                          <TH>Name</TH>
                          <TH>Role</TH>
                          <TH>Time Zone</TH>
                          <TH>Last Sign In</TH>
                          <TH>Actions</TH>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          data.agents.items.map(agent => (
                            <tr key={agent.id}>
                              <TD>
                                <p>{agent.firstName} {agent.lastName}</p>
                                <p className="text-xs text-gray-500">{agent.email}</p>
                              </TD>
                              <TD>{agent.role}</TD>
                              <TD>{agent.timeZoneName}</TD>
                              <TD>{agent.lastSignInAt}</TD>
                              <TD>
                                <DropdownButton label="Actions">
                                  <DropdownItemButton onClick={() => setActiveModal({ type: "UPDATE_ROLE", agentId: agent.id })}>
                                    Change Role
                                  </DropdownItemButton>
                                </DropdownButton>
                              </TD>
                            </tr>
                          ))
                        }
                      </tbody>
                    </Table>
                  </TableContainer>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
