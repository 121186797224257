import { FC } from "react";
import { AppealRequestEvent, AppealRequestEventModel } from "./AppealRequestEvent";
import { UmRequestEvent, UmRequestEventModel } from "./UmRequestEvent";

interface ActivityLogProps {
  appealRequestEvents?: AppealRequestEventModel[];
  umRequestEvents?: UmRequestEventModel[];
};

export const ActivityLog: FC<ActivityLogProps> = props => {
  const { umRequestEvents = [], appealRequestEvents = [] } = props;

  return (
    <div className="flow-root">
      <ul className="-mb-8">
        {umRequestEvents.map((umReqEvt, idx) => (
          <UmRequestEvent
            key={umReqEvt.id}
            showLine={idx < umRequestEvents.length - 1}
            umRequestEvent={umReqEvt}
          />
        ))}
        {appealRequestEvents.map((appealReqEvt, idx) => (
          <AppealRequestEvent
            key={appealReqEvt.id}
            showLine={idx < appealRequestEvents.length - 1}
            appealRequestEvent={appealReqEvt}
          />
        ))}
      </ul>
    </div>
  );
};
