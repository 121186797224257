import { FC } from "react";
import { Switch, Route } from "react-router-dom";
import {
  Layout,
  Sidebar,
  SidebarLink,
  PageContent,
} from "layouts/PortalLayout";
import { NoMatchScreen } from "screens/NoMatchScreen";

interface HealthPlanPortalProps { }

export const HealthPlanPortal: FC<HealthPlanPortalProps> = (props) => {
  return (
    <Layout>
      <Sidebar userDisplayName="Health Plan User">
        <SidebarLink to="/ip/dashboard" icon="home">
          Dashboard
        </SidebarLink>
        <SidebarLink to="/ip/team" icon="users">
          Team
        </SidebarLink>
        <SidebarLink to="/ip/projects" icon="folder">
          Projects
        </SidebarLink>
        <SidebarLink to="/ip/calendar" icon="calendar">
          Calendar
        </SidebarLink>
        <SidebarLink to="/ip/appeals" icon="inbox">
          Post-Decision Requests
        </SidebarLink>
        <SidebarLink to="/ip/analytics" icon="chartBar">
          Analytics
        </SidebarLink>
      </Sidebar>
      <PageContent>
        <Switch>
          <Route path="*">
            <NoMatchScreen />
          </Route>
        </Switch>
      </PageContent>
    </Layout>
  );
};
