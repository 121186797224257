import { FC, createContext, useContext } from "react";
import { TimeRange } from "./model";

interface State {
  timeRange: TimeRange;
}

const reportsContext = createContext<State>(null!);

interface AnalyticsProviderProps {
  timeRange: TimeRange;
}

export const AnalyticsProvider: FC<AnalyticsProviderProps> = (props) => {
  const { timeRange, children } = props;

  return (
    <reportsContext.Provider value={{ timeRange }}>
      {children}
    </reportsContext.Provider>
  );
};

export function useAnalytics() {
  const context = useContext(reportsContext);
  if (context === undefined) {
    throw new Error(`useAnalytics must be used within a AnalyticsProvider`);
  }
  return context;
}
