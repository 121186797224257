import { lightFormat } from "date-fns";
import { gql, useQuery } from "@apollo/client";
import { LineChart } from "../../charts/LineChart";
import { CircleIcon } from "components/CircleIcon";
import { hMmA, mmDdYy } from "utils/dateFormatters";
import { Button } from "components/Button";
import { useAnalytics } from "../../AnalyticsContext";

const UM_FAILED_ASSIGNMENTS_OVER_TIME = gql`
  query UmFailedAssignmentsOverTime($dateRange: InclusiveDateRangeInput!) {
    analyticsUmFailedAssignmentsOverTime(dateRange: $dateRange) {
      timeRange {
        start
        finish
      }
      total
      data {
        datetime
        value
      }
    }
  }
`;

interface Data {
  analyticsUmFailedAssignmentsOverTime: {
    timeRange: {
      start: string;
      finish: string;
    };
    total: number;
    data: {
      datetime: string;
      value: number;
    }[];
  };
}

function transform(data: { datetime: string, value: number }[]) {
  const p = data.map((d) => ({
    ...d,
    datetime: `${mmDdYy(d.datetime)} ${hMmA(d.datetime)}`,
  }));
  return p;
}

export function UMFailedAssignmentsOverTime() {
  const { timeRange } = useAnalytics();
  const dateRange = {
    start: lightFormat(timeRange.start, "yyyy-MM-dd"),
    finish: lightFormat(timeRange.finish, "yyyy-MM-dd"),
  };

  const { data, loading, error, refetch } = useQuery<Data>(UM_FAILED_ASSIGNMENTS_OVER_TIME, {
    variables: { dateRange },
  });

  return (
    <div className="_UMFailedAssignmentsOverTime m-6">
      <div className="p-4 bg-white rounded-lg shadow-lg">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <CircleIcon icon="chart-line" />
            <h4 className="ml-3 text-xl font-semibold text-gray-800">
              UM Failed Assignments Over Time
            </h4>
          </div>
          {/* <Button
            type="button"
            kind="tertiary"
            className="border"
            onClick={() => setActiveModal("EXPORT_WEBFORM_SUBMISSION_VOLUME")}
          >
            Export CSV{" "}
            <span className="ml-2">
              <FAIcon icon="file-download" />
            </span>
          </Button> */}
        </div>
        <div className="section pt-8">
          {loading ? (
            <p>Loading...</p>
          ) : error || !data?.analyticsUmFailedAssignmentsOverTime ? (
            <div>
              <h1>Failed to Load</h1>
              <Button type="button" onClick={() => refetch()}>
                Retry
              </Button>
            </div>
          ) : (
            <>
              <div>
                <p>
                  Total during range:{" "}
                  {data.analyticsUmFailedAssignmentsOverTime.total}
                </p>
              </div>
              <div className="p-8 text-left">
                <LineChart
                  data={transform(data.analyticsUmFailedAssignmentsOverTime.data)}
                  xValKey="datetime"
                  yValKey="value"
                  yValLabel="Failed Assignments"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
