import { FC } from "react";
import { FilterForm } from "components/FilterForm";
import { FilterField } from "components/FilterField";
import { TextInput } from "components/formik/TextField";
import { Button } from "components/Button";
import { removeVoidKeys } from "utils/removeVoidKeys";

export interface FilterModel extends Record<string, any> {
  searchTerm?: string;
}

export const defaultFilter: FilterModel = {
  searchTerm: "",
};

interface FilterPanelProps {
  value: FilterModel;
  onChange(filter: FilterModel): void;
  isLoading?: boolean;
}

export const FilterPanel: FC<FilterPanelProps> = (props) => {
  const { value, onChange, isLoading = false } = props;

  return (
    <FilterForm<FilterModel>
      defaultValue={defaultFilter}
      value={value}
      onChange={(values) => onChange(removeVoidKeys<FilterModel>(values))}
    >
      <div className="flex items-end gap-2">
        <FilterField htmlFor="searchTerm" icon="search" label="Search Term">
          <div className="w-48">
            <TextInput
              name="searchTerm"
              placeholder="Search..."
              icon="search"
            />
          </div>
        </FilterField>

        <FilterField>
          <Button
            type="submit"
            color="gold"
            disabled={isLoading}
            isLoading={isLoading}
          >
            Search
          </Button>
        </FilterField>
      </div>
    </FilterForm>
  );
};
